import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bausparforderung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Sicherheit in Krisenzeiten: Die Bausparförderung in Österreich" showCalc={false} />
            <Article>
                <p>
                    Gerade in Krisenzeiten ist es besonders wichtig, für möglichst viel Sicherheit zu sorgen. Die
                    größtmögliche Sicherheit bietet dir ein finanzieller Polster, der die ungewisse Zukunft nicht mehr
                    so bedrohlich aussehen lässt. Das heißt für dich also:{" "}
                    <Link to="/artikel/sparen-immobilien-kauf/" target="_blank" rel="noreferrer noopener">
                        Sparen
                    </Link>
                    !
                </p>
                <p>
                    In Österreich ist die beliebteste Sparform das Bausparen. Das liegt hauptsächlich daran, dass
                    Bausparen vom Staat unterstützt wird. Es gibt in Österreich eine sogenannte Bausparförderung, das
                    heißt eine staatliche Prämie, die das Bausparen fördert. Zusätzlich zu den Bausparzinsen bekommst du
                    bei der Bausparförderung also einen gewissen Prozentsatz deiner jährlich eingezahlten Ersparnisse
                    als Bonus. Vor allem in Zeiten, in denen die Zinsen eher niedrig sind, ist das ein attraktives
                    Sparmodell.
                </p>
                <p>
                    <a className="btn" href={urls.kreditrechner}>
                        Zu unserem Kreditrechner
                    </a>
                </p>
                <hr />

                <h2>Wie hoch ist die Bausparförderung?</h2>
                <p>
                    Die Höhe der ausgezahlten Prämie ist in Österreich von der{" "}
                    <Link to="/artikel/kreditzinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsentwicklung
                    </Link>{" "}
                    Kapitalmarkt abhängig. Sie wird jeden Herbst neu festgelegt. Dieses Jahr beträgt sie 1,5 % und liegt
                    damit bereits seit 2015 auf einem Tiefstand. Ob es sich trotz der relativ kleinen Bausparförderung
                    lohnt, einen Bausparvertrag einzugehen? Dieser Frage wollen wir uns jetzt widmen.
                </p>
                <hr />

                <h2>Ist Bausparen immer sinnvoll?</h2>
                <p>
                    Bausparen ist in Österreich nicht grundlos das beliebteste Sparmodell. Es geht schließlich um eine
                    zusätzliche Summe, die mit den{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    noch zu deinem gesparten Betrag hinzukommt. Es handelt sich bei dieser Prämie also tatsächlich um
                    einen geschenkten Betrag, der dein Ersparnis vergrößert. Bausparen ist also für jede Person
                    sinnvoll, die auch nur recht kleine Summen jährlich zur Seite legen kann.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Lass dich unabhängig beraten
                    </a>
                </p>
                <hr />

                <h2>Welche Konditionen erwarten mich?</h2>
                <p>
                    Die Auszahlung der Prämie einer Bausparförderung ist jedoch an einige Bedingungen geknüpft. Diese
                    Bedingungen betreffen die Laufzeit, die Personenanzahl und die Höhe des eingezahlten Betrags.
                </p>
                <p>
                    Eine Voraussetzung für die Bausparförderung ist zum Beispiel, dass der Bausparvertrag bis zum Ende
                    der Laufzeit von 6 Jahren bestehen bleiben muss. Außerdem können maximal 1.200 Euro jährlich und pro
                    Person prämienbegünstigt auf das Bausparkonto eingezahlt werden. Die Prämie ist dabei natürlich von
                    der Kapitalertragssteuer befreit. Der Bausparvertrag muss auf eine natürliche Person abgeschlossen
                    werden, die in Österreich unbeschränkt steuerpflichtig ist. Bei einer vorzeitigen Kündigung des
                    Bausparvertrags muss es eine bestimmte Zweckwidmung des Ersparten geben.
                </p>
                <p>
                    Außerdem gibt es verschiedene Bauspartarife. Der Normalspartarif, Jugendspartarife, Langsam- oder
                    Schnellspartarife, usw. Informiere dich im voraus über die verschiedenen Möglichkeiten. Es gibt
                    online auch Tarif-Vergleiche, die hilfreich sein können. Auch wichtig ist es, dich vorher über
                    unterschiedliche Zinsmodelle zu informieren. Über all diese Dinge kannst du auch mit unseren
                    Finanzexperten sprechen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>Wann bekommt man die Bausparförderung ausgezahlt?</h2>
                <p>
                    Die Bausparförderung wird ausgezahlt, sobald die Laufzeit zu Ende ist. Bei vorzeitiger
                    Vertragskündigung gibt es Sonderfälle, wie zum Beispiel, dass man den Verwendungszweck für das Geld
                    angeben muss und nur in bestimmten Fällen eine Prämie erhält.
                </p>
                <hr />

                <h2>Darf jede*r Bausparen?</h2>
                <p>
                    Jede Person, die ihren Hauptwohnsitz in Österreich hat und unbeschränkt steuerpflichtig ist, darf
                    mit einer der österreichischen Bausparkassen oder Banken einen Bausparvertrag abschließen. Auch
                    minderjährige Personen können Bausparen und die Bausparförderung erhalten. Doch der Vertrag muss von
                    einem gesetzlichen Vertreter, oder einer gesetzlichen Vertreterin unterschrieben werden. Deshalb
                    sind Bausparkonten bis zur Volljährigkeit in Österreich so beliebte Geschenke an Kinder und
                    Enkelkinder. Wenn du als jugendliche Person ab 14 Jahren bereits über dein eigenes Einkommen
                    verfügst, kannst du den Vertrag auch selbst unterschreiben. Die staatliche Bausparförderung gilt
                    aber immer nur einem Bausparvertrag pro Person. Man kann also nicht mehrere Prämien erhalten.
                </p>
                <hr />

                <h2>Welche Kosten fallen an?</h2>
                <p>
                    Die Kosten für ein Bausparkonto sind von Bank zu Bank unterschiedlich. Meist handelt es sich dabei
                    um einen kleinen jährlichen Betrag unter zehn Euro.
                </p>
                <hr />

                <h2>Was du außerdem wissen solltest</h2>
                <p>
                    Das Geld aus deinem Bausparvertrag musst du nicht unbedingt für einen{" "}
                    <Link to="/artikel/baukonto/" target="_blank" rel="noreferrer noopener">
                        Hausbau
                    </Link>{" "}
                    oder Immobilienkauf ausgeben. Nach Ablauf des Vertrages darfst du dein erspartes Geld auch für
                    andere Zwecke verwenden. Das bleibt ganz dir überlassen.
                </p>
                <p>
                    Geförderte Bausparverträge sind mit jeder Bank möglich. Bevor du dich für eine entscheidest,
                    solltest du dich aber über ihre jeweiligen{" "}
                    <Link to="/artikel/konditionen/" target="_blank" rel="noreferrer noopener">
                        Konditionen
                    </Link>{" "}
                    informieren. Sprich deshalb mit Finanzexpert*innen, lass dich beraten und informiere dich gut, bevor
                    du einen Vertrag abschließt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Check dir einen Beratungstermin!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bausparforderung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"bausparforderung"}
                heading={"Sicherheit in Krisenzeiten: Die Bausparförderung in Österreich"}
            />
        </Layout>
    );
};

export default Bausparforderung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bausparforderung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
